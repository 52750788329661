import { CommonModule } from '@angular/common';
import {
  Component,
  InjectionToken,
  computed,
  inject,
  input,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { Address } from '@husky/app/shared/data-access';

export const GoogleMapsApiKey = new InjectionToken<string>('GoogleMapsApiKey');

@Component({
  selector: 'husky-app-shared-ui-map',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './app-shared-ui-map.component.html',
  styleUrl: './app-shared-ui-map.component.scss',
})
export class AppSharedUiMapComponent {
  address = input.required<Pick<Address, 'street' | 'zipcode' | 'city'>>();

  mapsAddressString = computed(() => {
    const address = this.address();

    const addressString = address
      ? `${address.street}, ${address.zipcode} ${address.city}`
      : undefined;

    if (!addressString) {
      return undefined;
    }

    // TODO: Potential XSS?
    /** https://developers.google.com/maps/documentation/embed/embedding-map?hl=de */
    return this.domSanitizer.bypassSecurityTrustResourceUrl(
      `https://www.google.com/maps/embed/v1/place?key=${this.googleMapsApiKey}&q=${encodeURIComponent(addressString)}&maptype=satellite&language=de&zoom=19`,
    );
  });

  private readonly googleMapsApiKey: string = inject(GoogleMapsApiKey);

  constructor(private readonly domSanitizer: DomSanitizer) {}
}
